import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const addNotification = (title, message, type) =>
  store.addNotification({
    title,
    message,
    type, // success, danger, info, default, warning
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });

export default addNotification;
