import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { hexToRGBA } from 'utils/lib';
import { colors } from 'utils/variables';

const themeMapper = {
  default: {
    labelColor: colors.color1,
    textareaBackgroundColor: colors.color1,
    textareaBorder: '1px solid transparent',
    textareaBorderColorHover: colors.color2,
    textareaBackgroundColorHover: colors.color1,
    textareaBorderColorFocus: colors.color2,
  },
};

const stateMapper = {
  error: {
    labelColor: colors.error,
    textareaBorder: `1px solid ${colors.error}`,
    textareaBorderColorFocus: colors.error,
  },
  success: {
    labelColor: colors.success,
    textareaBorder: `1px solid ${colors.success}`,
    textareaBorderColorFocus: colors.success,
  },
};

const StyledText = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 4rem 1.5rem 3rem 1.5rem;
`;

const StyledLabel = styled.label`
  z-index: 10;
  display: inline-flex;
  position: absolute;
  align-items: center;
  font-weight: 400; /* regular */
  font-size: 1.5rem;
  margin-left: 3rem;
  cursor: text;
  color: ${({ theme }) => theme.labelColor};
  height: 6rem;
  transition: margin 300ms ease, opacity 300ms ease-out;
  margin-top: ${({ filled }) => filled && '-5.1rem'};
  opacity: ${({ filled }) => (filled ? '1' : '0')};
`;

const StyledTextarea = styled.textarea`
  z-index: 20;
  min-height: 13.8rem;
  width: calc(100% - 6rem);
  padding: 2rem 3rem 0 3rem;
  background-color: ${({ theme }) => theme.textareaBackgroundColor};
  border: ${({ theme }) => theme.textareaBorder};
  border-radius: 0.3rem;
  outline: 0;
  caret-color: ${hexToRGBA(colors.white, 0.7)};
  color: ${hexToRGBA(colors.white, 0.8)};
  font-weight: 400; /* regular */
  font-size: 1.5rem;
  transition: border 300ms ease;

  &::placeholder {
    color: ${hexToRGBA(colors.white, 0.8)};
    font-weight: 400; /* regular */
    font-size: 1.5rem;
    transition: opacity 500ms ease;
  }

  &:hover {
    border-color: ${({ theme }) => theme.textareaBorderColorHover};
    background-color: ${({ theme }) => theme.textareaBackgroundColorHover};
  }

  &:focus::placeholder {
    opacity: 0;
    transition: opacity 50ms ease;
  }

  &:focus ~ label {
    margin-top: -5.1rem;
    opacity: 1;
  }

  &:focus {
    border-color: ${({ theme }) => theme.textareaBorderColorFocus};
  }
`;

const StyledMsgBox = styled.span`
  z-index: 10;
  display: flex;
  position: relative;
  align-items: center;
  font-weight: 400; /* regular */
  font-size: 1.2rem;
  cursor: text;
  color: ${colors.error};
  transition: transform 300ms ease, opacity 300ms ease;
  margin: -2rem 0 0 3rem;
  transform: ${({ children }) => children && 'translateY(2.5rem)'};
  opacity: ${({ children }) => (children ? '1' : '0')};
`;

const Textarea = ({ theme, name, label, value, required, success, errorMessage, onChange, onBlur }) => (
  <ThemeProvider theme={{ ...themeMapper[theme], ...stateMapper[success && 'success'], ...stateMapper[errorMessage && 'error'] }}>
    <StyledText className="textarea">
      <StyledTextarea
        value={value}
        placeholder={`${label}${required ? '*' : ''}`}
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
      <StyledLabel filled={!!value} htmlFor={name}>{`${label}${required ? '*' : ''}`}</StyledLabel>
      <StyledMsgBox>{errorMessage}</StyledMsgBox>
    </StyledText>
  </ThemeProvider>
);

Textarea.propTypes = {
  theme: PropTypes.oneOf(['default', 'dark']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Textarea.defaultProps = {
  theme: 'default',
  label: '',
  value: '',
  required: false,
  success: false,
  errorMessage: '',
  onChange: () => {},
  onBlur: () => {},
};

export default Textarea;
