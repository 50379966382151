import React from 'react';
import Layout from 'templates/Layout/Layout';
import Footer from 'components/organisms/Footer/Footer';
import ContactHeader from 'components/organisms/ContactHeader/ContactHeader';
import ContactForm from 'components/organisms/ContactForm/ContactForm';

const Page = () => (
  <Layout
    metaTitle="Skontaktuj się z nami - Auto Serwis Kubeczek"
    metaDescription="Skontaktuj się z naszym serwisem samochodowym - Kubeczek auto serwis. Kompleksowa naprawa oraz serwisowanie samochodów w Rybniku."
  >
    <ContactHeader />
    <ContactForm />
    <Footer short />
  </Layout>
);

export default Page;
