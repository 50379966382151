import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Input from 'components/atoms/Input/Input';
import Textarea from 'components/atoms/Textarea/Textarea';
import Button from 'components/atoms/Button/Button';
import { breakPoints, colors } from 'utils/variables';
import { postData } from 'utils/lib';
import { H2 } from 'components/atoms/Typography';
import addNotification from 'components/molecules/Notification/Notification';
import Container from 'components/atoms/Container/Container';

const StyledContainer = styled.div`
  z-index: 15;
  margin-top: 5rem;
  margin-bottom: -5rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-bottom: 5rem;
    padding-top: 2rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-bottom: -5rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;

  @media (min-width: ${breakPoints.desktop}) {
    width: 90rem;
    margin: auto;
  }

  button {
    display: block;
    margin: 3rem auto 8rem auto;
    width: 100%;
    height: 6rem;
    transition: filter 300ms ease;

    &:disabled {
      filter: grayscale(100);
    }

    @media (min-width: ${breakPoints.tablet}) {
      width: 30rem;
      margin: 2.5rem auto 0 auto;
    }
  }

  .text-input {
    padding: 0;
    margin: 6rem 0 0 0;
    width: 100%;

    @media (min-width: ${breakPoints.desktop}) {
      margin: 9rem 0 0 0;
      width: 40rem;
    }
  }

  .textarea {
    padding: 0;
    margin: 8rem 0 0 0;
  }

  .agreement {
    width: 100%;
    margin-top: 3.8rem;

    label {
      font-weight: 300;
      color: ${colors.color2};
    }

    a {
      font-weight: 400;
      color: ${colors.color2};

      &:hover {
        color: ${colors.color5};
      }
    }
  }
`;

const StyledStatement = styled.p`
  margin: 8rem 0;
  text-align: center;
  font-size: 1.6rem;
  line-height: 3rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 15rem 0;
    font-size: 2rem;
    line-height: 3.5rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin: 20rem 0;
    font-size: 3rem;
    line-height: 4rem;
  }
`;

const StyledHeading = styled.p`
  text-align: center;
  margin: auto;
  margin-top: 8rem;
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight: 600;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 10rem;
    width: 50rem;
    font-size: 2rem;
    line-height: 3.5rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 65rem;
    font-size: 3rem;
    line-height: 4rem;
  }
`;

const ContactForm = () => {
  const [isSend, setSend] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Niepoprawny adres email')
      .required('To pole jest wymagane'),
    name: Yup.string()
      .min(2, 'Imię jest zbyt krótkie')
      .max(50, 'Imię jest zbyt długie')
      .matches(/^[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+$/, 'Niepoprawne imię')
      .required('To pole jest wymagane'),
    message: Yup.string()
      .min(5, 'Wiadomość jest zbyt krótka')
      .required('To pole jest wymagane'),
    agreement_contact_form: Yup.bool().oneOf([true], 'To pole jest wymagane'),
  });
  const initialValues = {
    email: '',
    name: '',
    message: '',
    agreement_contact_form: false,
  };
  const onSubmit = (values, { setSubmitting }) => {
    postData(values, '').then(
      response =>
        response.status === 200 ? setSend(true) : addNotification('Wystąpił błąd', 'Coś poszło nie tak, spróbuj ponownie za chwilę', 'danger'),
      setSubmitting(false),
    );
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setTouched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <StyledContainer as={Container}>
      {isSend ? (
        <StyledStatement as={H2} color={colors.color1}>
          Dziękujemy za wiadomość!
          <br />
          Odezwiemy się w najbliższym czasie.
        </StyledStatement>
      ) : (
        <>
          <StyledHeading as={H2}>Lub wyślij nam wiadomość, a my odpowiemy najszybciej jak to możliwe</StyledHeading>
          <StyledForm onSubmit={handleSubmit} isSend={isSend}>
            <Input
              name="email"
              label="Adres email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              success={!errors.email && !!values.email}
              errorMessage={errors.email && touched.email && errors.email}
            />
            <Input
              name="name"
              label="Imię"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              success={!errors.name && !!values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <Textarea
              name="message"
              label="Treść wiadomości"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              success={!errors.message && !!values.message}
              errorMessage={errors.message && touched.message && errors.message}
            />
            <Checkbox
              label={`Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych. Zobacz naszą <a title="politykę prywatności" href="/polityka-prywatnosci">politykę prywatności</a>.`}
              name="agreement_contact_form"
              specificClass="agreement"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.agreement_contact_form}
              success={!errors.agreement_contact_form && !!values.agreement_contact_form}
              errorMessage={errors.agreement_contact_form && touched.agreement_contact_form && errors.agreement_contact_form}
              required
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              onClick={() =>
                setTouched({
                  messageContext: true,
                  email: true,
                  name: true,
                  lastname: true,
                  message: true,
                  agreement_contact_form: true,
                })
              }
            >
              Wyślij wiadomość
            </Button>
          </StyledForm>
        </>
      )}
    </StyledContainer>
  );
};

export default ContactForm;
