import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { colors } from 'utils/variables';

const themeDefault = {
  labelColor: colors.dark,
  labelBeforeBorder: colors.dark,
  inputCheckedLabelAfterBackground: colors.dark,
};

const stateMapper = {
  error: {
    labelColor: colors.error,
    labelBeforeBorder: colors.error,
    inputCheckedLabelAfterBackground: colors.error,
  },
  success: {
    labelColor: colors.success,
    labelBeforeBorder: colors.success,
    inputCheckedLabelAfterBackground: colors.success,
  },
};

const StyledCheckbox = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

const StyledLabel = styled.label`
  display: inline-flex;
  width: 100%;
  color: ${({ theme }) => theme.labelColor};
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.5rem;
  margin: 0 0.8rem 2rem 0;
  cursor: pointer;
  z-index: 100;
  position: relative;

  &:before {
    content: ' ';
    box-sizing: border-box;
    display: inline-block;
    top: 0;
    left: 0;
    min-height: 2.5rem;
    min-width: 2.5rem;
    max-height: 2.5rem;
    max-width: 2.5rem;
    margin-right: 3.5rem;
    border-radius: 0.3rem;
    border: 0.2rem solid ${({ theme }) => theme.labelBeforeBorder};
  }

  &:hover:before {
    background-color: ${colors.white};
  }

  a {
    display: contents;
    color: ${colors.color1};
  }
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  border: 0;

  &:checked ~ label:after {
    content: ' ';
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.2rem;
    background-color: ${({ theme }) => theme.inputCheckedLabelAfterBackground};
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
  }
`;

const StyledMsgBox = styled.span`
  z-index: 10;
  display: flex;
  position: relative;
  align-items: center;
  font-weight: 400; /* regular */
  font-size: 1.2rem;
  cursor: text;
  color: ${colors.error};
  transition: transform 300ms ease, opacity 300ms ease;
  margin: -2rem 0 0 6rem;
  transform: ${({ children }) => !children && 'translateY(-2rem)'};
  opacity: ${({ children }) => (children ? '1' : '0')};
`;

const Checkbox = ({ name, label, checked, required, success, errorMessage, onChange, onBlur, specificClass }) => (
  <ThemeProvider theme={{ ...themeDefault, ...stateMapper[success && 'success'], ...stateMapper[errorMessage && 'error'] }}>
    <StyledCheckbox className={`custom-checkbox ${specificClass}`}>
      <StyledInput checked={checked} type="checkbox" id={name} name={name} required={required} onChange={onChange} onBlur={onBlur} />
      <StyledLabel htmlFor={name} dangerouslySetInnerHTML={{ __html: `${label}${required ? '*' : ''}` }} />
      <StyledMsgBox>{errorMessage}</StyledMsgBox>
    </StyledCheckbox>
  </ThemeProvider>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  specificClass: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  checked: false,
  required: false,
  success: false,
  errorMessage: '',
  onChange: () => {},
  onBlur: () => {},
  specificClass: '',
};

export default Checkbox;
