import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { hexToRGBA } from 'utils/lib';
import { colors } from 'utils/variables';

const themeMapper = {
  default: {
    labelColor: colors.color1,
    inputBackgroundColor: colors.color1,
    inputBorder: '1px solid transparent',
    inputBorderColorHover: colors.color2,
    inputBackgroundColorHover: colors.color1,
    inputBorderColorFocus: colors.color2,
  },
};

const stateMapper = {
  error: {
    labelColor: colors.error,
    inputBorder: `1px solid ${colors.error}`,
    inputBorderColorFocus: colors.error,
  },
  success: {
    labelColor: colors.success,
    inputBorder: `1px solid ${colors.success}`,
    inputBorderColorFocus: colors.success,
  },
};

const StyledText = styled.div`
  display: inline-flex;
  padding: 3rem 1.5rem 3rem 1.5rem;
`;

const StyledLabel = styled.label`
  z-index: 10;
  display: inline-flex;
  position: absolute;
  align-items: center;
  font-weight: 400; /* regular */
  font-size: 1.5rem;
  margin-left: 3rem;
  cursor: text;
  color: ${({ theme }) => theme.labelColor};
  height: 6rem;
  transition: margin 300ms ease, opacity 300ms ease-out;
  margin-top: ${({ filled }) => filled && '-5.1rem'};
  opacity: ${({ filled }) => (filled ? '1' : '0')};
`;

const StyledInput = styled.input`
  z-index: 20;
  height: 5.8rem;
  width: calc(100% - 6rem);
  padding: 0 3rem;
  background-color: ${({ theme }) => theme.inputBackgroundColor};
  border: ${({ theme }) => theme.inputBorder};
  border-radius: 0.3rem;
  outline: 0;
  caret-color: ${hexToRGBA(colors.white, 0.7)};
  color: ${hexToRGBA(colors.white, 0.8)};
  font-weight: 400; /* regular */
  font-size: 1.5rem;
  transition: border 300ms ease;

  &::placeholder {
    color: ${hexToRGBA(colors.white, 0.8)};
    font-weight: 400; /* regular */
    font-size: 1.5rem;
    transition: opacity 500ms ease;
  }

  &:hover {
    border-color: ${({ theme }) => theme.inputBorderColorHover};
    background-color: ${({ theme }) => theme.inputBackgroundColorHover};
  }

  &:focus::placeholder {
    opacity: 0;
    transition: opacity 50ms ease;
  }

  &:focus ~ label {
    margin-top: -5.1rem;
    opacity: 1;
  }

  &:focus {
    border-color: ${({ theme }) => theme.inputBorderColorFocus};
  }
`;

const StyledMsgBox = styled.span`
  z-index: 10;
  display: inline-flex;
  position: absolute;
  align-items: center;
  font-weight: 400; /* regular */
  font-size: 1.2rem;
  cursor: text;
  color: ${colors.error};
  transition: margin-top 300ms ease, opacity 300ms ease-out;
  margin: ${({ children }) => (children ? '6.5rem 0 0 3rem' : '0 0 0 3rem')};
  opacity: ${({ children }) => (children ? '1' : '0')};
`;

const Input = ({ theme, name, label, value, required, success, errorMessage, onChange, onBlur }) => (
  <ThemeProvider theme={{ ...themeMapper[theme], ...stateMapper[success && 'success'], ...stateMapper[errorMessage && 'error'] }}>
    <StyledText className="text-input">
      <StyledInput
        value={value}
        placeholder={`${label}${required ? '*' : ''}`}
        type="text"
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
      <StyledLabel filled={!!value} htmlFor={name}>{`${label}${required ? '*' : ''}`}</StyledLabel>
      <StyledMsgBox>{errorMessage}</StyledMsgBox>
    </StyledText>
  </ThemeProvider>
);

Input.propTypes = {
  theme: PropTypes.oneOf(['default', 'dark']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  theme: 'default',
  label: '',
  value: '',
  required: false,
  success: false,
  errorMessage: '',
  onChange: () => {},
  onBlur: () => {},
};

export default Input;
