import React from 'react';
import styled, { keyframes } from 'styled-components';
import Text, { H1, H4 } from 'components/atoms/Typography';
import { breakPoints, colors } from 'utils/variables';
import Container from 'components/atoms/Container/Container';
import Menu from 'components/organisms/Menu/Menu';
import BgAnimated from 'images/header-shapes.inline.svg';
import BgImage from 'images/header-contact-image.inline.svg';

const StyledWrapper = styled.div`
  margin: 0;
  height: 53rem;
  opacity: 0;
  animation: ${keyframes`
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `} 1500ms ease forwards;
  position: relative;
  z-index: 10;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 0 8rem 0;
    height: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin: 0;
    height: 33.5rem;
  }

  a {
    #mapa {
      transition: filter 150ms linear;
    }

    #shape {
      animation: ${keyframes`
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-0.5rem);
      }
    `} 1500ms ease-in-out infinite;
    }

    &:hover #mapa {
      filter: drop-shadow(0 0.3rem 0.6rem rgba(0, 0, 0, 0.8));
    }
  }
`;

const StyledContent = styled.div`
  position: relative;
  margin-top: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 15rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 28rem;
  }

  h4 {
    margin-top: 2rem;
    font-weight: 600;
  }

  .text {
    font-size: 1.4rem;
    line-height: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    @media (min-width: ${breakPoints.tablet}) {
      font-size: 1.6rem;
    }
  }

  .nip,
  .email,
  .phone {
    color: ${colors.color5};
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 2.5rem;
    display: flex;
    margin-bottom: 0rem;

    @media (min-width: ${breakPoints.tablet}) {
      font-size: 1.6rem;
    }
  }

  .nip {
    margin-bottom: 15px;
  }
`;

const StyledLinkText = styled.a`
  color: ${colors.color5};
  font-weight: 300;
  font-size: 1.4rem;
  border: 0;
  display: block;
  text-decoration: none;
  letter-spacing: 0.05rem;

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.5rem;
  }

  &:hover {
    border: 0;
    color: ${colors.color1};
  }
`;

const StyledImage = styled.svg`
  display: block;
  position: relative;
  padding: 0;
  margin: 3rem auto 0 auto;
  width: 28rem;
  max-height: 28rem;
  left: 0;
  right: 0;

  @media (min-width: ${breakPoints.tablet}) {
    position: absolute;
    top: -3rem;
    width: 35rem;
    transform: translateX(35.5rem);
    max-height: unset;
    margin: 0;
    left: unset;
    right: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 60rem;
    max-width: unset;
    top: -12.2rem;
    transform: translateX(61.3rem);
  }
`;

const StyledAnimated = styled.svg`
  display: block;
  position: relative;
  padding: 0;
  margin: 0 auto;
  transform: translate(0.7rem, -28.5rem);
  width: 30rem;
  max-height: 28rem;
  overflow: visible;

  @media (min-width: ${breakPoints.tablet}) {
    position: absolute;
    top: -6rem;
    width: 38rem;
    transform: translateX(35rem);
    max-height: unset;
    margin: 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: auto;
    max-width: unset;
    top: -17.3rem;
    transform: translate(62rem, 2rem);
  }

  #shape1,
  #shape2,
  #shape3,
  #shape4,
  #shape5,
  #shape6 {
    @media (min-width: ${breakPoints.tablet}) {
      animation: ${keyframes`
        0%, 100% {
          transform: ${`translate(${Math.floor(Math.random() * 4) - 1.5}rem, ${Math.floor(Math.random() * 4) - 1.5}rem)`};
        }
        50% {
          transform: ${`translate(${Math.floor(Math.random() * 4) - 1.5}rem, ${Math.floor(Math.random() * 4) - 1.5}rem)`};
        }
      `} ease-in-out infinite;
    }
  }

  #shape1 {
    animation-duration: 5000ms;
  }

  #shape2 {
    animation-duration: 7000ms;
  }

  #shape3 {
    animation-duration: 6000ms;
  }

  #shape4 {
    animation-duration: 7000ms;
  }

  #shape5 {
    animation-duration: 5000ms;
  }

  #shape6 {
    animation-duration: 6000ms;
  }
`;

const ContactHeader = () => (
  <Container>
    <Menu />
    <StyledWrapper>
      <StyledContent>
        <H1 color={colors.color1} dangerouslySetInnerHTML={{ __html: 'Skontaktuj <b>się z nami</b>' }} />
        <H4 color={colors.color1}>Auto-Części Adam Kubeczek</H4>
        <Text color={colors.color5}>
          Ul. Wodzisławska 100
          <br />
          44-218 Rybnik
        </Text>
        <span className="phone">
          Numer telefonu:
          <StyledLinkText title="504 865 436" href="tel: 504865436">
            &nbsp;504 865 436;
          </StyledLinkText>
          <StyledLinkText title="32 4249242" href="tel: 324249242">
            &nbsp;32 4249242
          </StyledLinkText>
        </span>
        <span className="email">
          {' '}
          Email:
          <StyledLinkText target="_blank" title="kontakt@serwis-kubeczek.pl" href="mailto: kontakt@serwis-kubeczek.pl">
            &nbsp;kontakt@serwis-kubeczek.pl
          </StyledLinkText>
        </span>
        <span className="nip">
          NIP:
          <StyledLinkText title="642-244-95-01">&nbsp;6422449501</StyledLinkText>
        </span>
        <iframe
          title="like-box"
          src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FAuto-Serwis-Kubeczek-107038131461353%2F&width=450&layout=standard&action=like&size=large&share=true&height=35&appId"
          width="450"
          height="35"
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </StyledContent>
      <a
        title="mapa"
        href="https://www.google.pl/maps/place/Auto-Cz%C4%99%C5%9Bci+KUBECZEK/@50.0842229,18.5289465,17.22z/data=!4m5!3m4!1s0x47114f1f433ceebf:0x723ed5306469e8c3!8m2!3d50.0843178!4d18.528205"
      >
        <StyledImage as={BgImage} />
        <StyledAnimated as={BgAnimated} />
      </a>
    </StyledWrapper>
  </Container>
);

export default ContactHeader;
